@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circe-Regular.eot');
    src: local('Circe'), local('Circe-Regular'),
    url('./fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Circe-Regular.woff') format('woff'),
    url('./fonts/Circe-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circe-Bold.eot');
    src: local('Circe Bold'), local('Circe-Bold'),
    url('./fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Circe-Bold.woff') format('woff'),
    url('./fonts/Circe-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circe-ExtraBold.eot');
    src: local('Circe ExtraBold'), local('Circe-ExtraBold'),
    url('./fonts/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Circe-ExtraBold.woff') format('woff'),
    url('./fonts/Circe-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'PTSans';
    src: local('PTSans Bold'), local('PTSans-Bold'),
    url('./fonts/PTSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'PTSans';
    src: local('PTSans Regular'), local('PTSans-Regular'),
    url('./fonts/PTSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
form.search__form {
    width: 225px;
    height: 19px;
    padding-right: 5px;
    font-size: 0;
    border-bottom: 1px solid #2c2a2980;
}

header {
    padding-top: 12px;
}

body {
    background: #F1F1F1;
    font-family: 'PTSans', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #2C2A29;
}

.search__submit {
    border: 0;
    display: inline-block;
    width: 13px;
    margin-right: 18px;
    height: 100%;
    vertical-align: top;
    padding: 0;
    background: url(../images/search-icon.png) top left no-repeat;
    cursor: pointer;
}

.search__input {
    border: 0;
    display: inline-block;
    background: transparent;
    font-size: 11px;
    line-height: 10px;
    vertical-align: top;
    width: 189px;
    margin: 0;
    padding: 0;
    outline: none;
}

.search__input::placeholder {
    color: rgba(44, 42, 41, 0.5);
}

.lang a {
    color: #2C2A29;
    font-family: 'Circe',sans-serif;
    font-size: 12px;
    line-height: 23px;
    font-weight: bold;
}

.lang a:first-child {
    margin-right: 12px;
}

.lang {
    margin-left: 40px;
}

.lk a {
    font-family: 'Circe',sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #ED5338;
    padding-right: 35px;
    background: url(../images/lk-icon.png) right center no-repeat;
}

.lk {
    margin-left: 30px;
}
.tp {
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-family: 'Circe',sans-serif;
    font-size: 12px;
    line-height: 23px;
    color: #2C2A29;
    font-weight: bold;
}
.tp:hover {
    color: #ED5338;
}
.separator--header {
    height: 8px;
}
.top-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-transform: uppercase;
    margin-bottom: 23px;
}

.top-menu a {
    padding: 0 13px;
    font-family: 'Circe',sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.025em;
    color: #2C2A29;
    text-decoration: none;
}
.top-menu a:hover,
.top-menu a.active{
    color: #ED5338;
}
.top-menu li {
    margin-left: 40px;
}

.top-menu li:first-child {
    margin-left: 0;
}
.mobile-menu {
    position: fixed;
    top: 0;
    left: -250px;
    width: 235px;
    height: 100vh;
    background: white;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
}
@media (max-width:991px){
    form.search__form {
        padding-right: 3px;
    }

    .search__submit {
        margin-right: 5px;
    }
    .personal-block {
        height: 69px!important;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start!important;
        justify-content: center!important;
        align-content: space-between;
    }

    .lk {
        margin: 0;
    }

    .lang {
        margin: 0;
        flex: 0 0 24px;
    }

    .top-menu a {
        font-size: 14px;
        line-height: 22px;
        padding: 0;
    }
}
@media (max-width:767px){
    .top-menu li {
        margin-left: 0;
    }

    .top-menu a {
        font-size: 16px;
        text-transform: none;
    }

    .separator--header {
        height: 15px;
    }

    .top-menu ul {
        margin-bottom: 15px;
        justify-content: space-between!important;
    }

    form.search__form {
        width: 100%;
    }

    .search__input {
        width: 96%;
    }
}
@media (max-width:575px){
    .separator--header {
        height: 30px;
    }
    .logo__image {
        margin-left: 8px;
    }
    .burger {
        width: 30px;
        height: 20px;
        border: none;
        background: none;
        padding: 0;
        position: relative;
        border-top: 2px solid #5E2D1D;
        margin-right: 8px;
        outline:none!important;
        transition: all 0.5s;
    }

    .burger:before {
        content: '';
        height: 2px;
        width: 75%;
        background: #5E2D1D;
        position: absolute;
        top: 7px;
        left: 0;
        transition: all 0.5s;
    }

    .burger:after {
        content: '';
        height: 2px;
        width: 100%;
        background: #5E2D1D;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s;
    }
    .burger.active {
        border-color: #5E2D1D00;
    }

    .burger.active:before {
        top: 0;
        width: 23px;
        transform-origin: left;
        transform: rotate(45deg);
    }

    .burger.active:after {
        transform-origin: left;
        transform: rotate(-45deg);
        width: 23px;
    }
    .mobile-menu.active {
        display: flex;
        left: 0;
    }
    .mobile-logo__image {
        margin-bottom: 15px;
    }
    .search__form--footer .search__input {
        width: 91%;
        letter-spacing: -0.1px;
    }

    form.search__form--footer {
        padding-right: 0;
        margin-bottom: 15px;
    }
    .tp--footer {
        background: none;
        margin-bottom: 5px;
    }
    .lk--footer {
        margin-bottom: 5px;
    }
    .mobile-menu--list {
        margin-bottom: 15px;
        padding: 0;
        list-style-type: none;
        width: 100%;
        flex-grow: 1;
        overflow: auto;
    }

    .mobile-menu--list a {
        color: black;
        display: block;
        text-align: center;
        padding: 2px 0;
        margin-bottom: 5px;
        font-size: 16px;
    }
}